import React, { Component } from 'react';

import { Button, Tabs } from 'library';
import { location_to_url } from 'functions';
import { WikiPageContent, CampaignNotes } from 'components';

export default class PointOfInterestDetails extends Component {
    render() {
        let location_has_position = this.props.location_has_position;
        let page_location = this.props.page_location;
        let tabs = {};

        let go_to_map = null;

        if (location_has_position.id == page_location.id) {
            if (page_location.parents.length > 0) {
                go_to_map = [];
            }
            for (let parent of page_location.parents) {
                if (parent.image != null) {
                    go_to_map.push(
                        <Button
                            type="success"
                            style={{ float: 'right' }}
                            href={location_to_url(
                                parent.name,
                                this.props.campaign_id,
                            )}
                        >
                            Venture To {parent.name} Map
                        </Button>,
                    );
                }
            }
        } else if (location_has_position.image) {
            go_to_map = (
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    href={location_to_url(
                        location_has_position.name,
                        this.props.campaign_id,
                    )}
                >
                    Venture To Map
                </Button>
            );
        }

        let info = <p>We do not have a link to the wiki for this location.</p>;
        if (
            location_has_position &&
            location_has_position['wiki_page_content'] &&
            location_has_position['wiki_page_content']['sections'].length > 0
        ) {
            info = (
                <WikiPageContent
                    name={location_has_position['name']}
                    data={location_has_position['wiki_page_content']}
                />
            );
        }

        tabs['Location Info'] = (
            <div>
                {go_to_map}
                <div style={{ clear: 'both' }}></div>
                {info}
            </div>
        );

        let campaign_location_settings = this.props.campaign_location_settings;
        if (!this.props.dm) {
            if (
                !campaign_location_settings ||
                campaign_location_settings.show_wiki == false
            ) {
                delete tabs['Location Info'];
            }
        }

        //not signed in, signed in but no campaign selected, campaign selection
        tabs['Your Campaign'] = (
            <CampaignNotes
                key={'notes_' + this.props.location_has_position.id}
                campaign={this.props.campaign}
                page_location={location_has_position}
                campaign_location_settings={campaign_location_settings}
                campaign_notes={this.props.campaign_notes}
                dm={this.props.dm}
            />
        );

        tabs['Close'] = <div></div>;

        return (
            <Tabs
                key={'tabs_' + this.props.location_has_position.id}
                tabs={tabs}
                select_poi={this.props.select_poi}
            />
        );
    }
}
