import React, { Component } from 'react';

import { Accordion, Image } from 'library';
import { location_to_url } from 'functions';

class Section extends Component {
    render() {
        let section = this.props.data;

        let section_jsx = [];
        for (let subsection of section['subsections']) {
            let content_text = '';
            if (subsection['name']) {
                section_jsx.push(<h5>{subsection['name']}</h5>);
            }
            for (let item of subsection['content']) {
                item = item.replaceAll("volo's_rating", 'volos_rating');

                content_text += item;
            }

            section_jsx.push(
                <div
                    dangerouslySetInnerHTML={{
                        __html: content_text,
                    }}
                ></div>,
            );
        }

        return (
            <div className={`wiki-section ${section['name'].toLowerCase()}`}>
                {section_jsx}
            </div>
        );
    }
}

export default class WikiPageContent extends Component {
    render() {
        let pretty_info = [];

        let index = 0;
        for (let section of this.props.data['sections']) {
            pretty_info.push(
                <Accordion index={index} name={section['name']}>
                    <Section index={index} data={section} />
                </Accordion>,
            );

            index += 1;
        }

        let image = null;
        if (this.props.data['image']) {
            image = (
                <div style={{ textAlign: 'center' }}>
                    <Image
                        src={this.props.data['image']}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '400px',
                            marginBottom: '10px',
                            borderRadius: '3px',
                        }}
                        title={this.props.data['image_text']}
                    />
                </div>
            );
        }

        let top_section = null;
        if ('name' in this.props.data['top_section']) {
            top_section = (
                <Section index={index} data={this.props.data['top_section']} />
            );
        }

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h3 style={{ marginBottom: '16px' }}>
                            <a
                                href={
                                    'https://forgottenrealms.fandom.com/wiki/' +
                                    this.props.name.replace("'", '%27')
                                }
                                target="_blank"
                            >
                                {this.props.name}
                            </a>
                        </h3>
                    </div>
                    <div className="col-12">{image}</div>
                    <div className="col-12">{top_section}</div>
                </div>
                {pretty_info}
            </div>
        );
    }
}
